import AppBarCommon from "../AppBarCommon";
import { Stack, Typography, Box } from "@mui/material";
import FirstImage from "../img/advertisementpageimg1.svg"
import SecondImage from "../img/advertisementpageimg2.svg"
import ThirdImage from "../img/advertisementpageimg3.svg"
import FourthImage from "../img/advertisementpageimg4.svg"
import FivethImage from "../img/advertisementpageimg5.svg"
import Fade from '@mui/material/Fade';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fab from '@mui/material/Fab';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';
import Footer from "../Footer"
import { Carousel } from 'react-responsive-carousel';



function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
        top: -50, behavior: 'smooth',
      });
    }
  };


  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', right:{xs:35, md:130}, top:{xs:"83vh", md:"92.5vh"}}}
      >
        {children}
      </Box>
    </Fade>
  );
}

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};




function Advertisement(props) {

  return (
    <>
      <AppBarCommon value={2} topArrow={"back-to-top-anchor"} />
      <Box sx={{ mt: "30px" }}>
        <Stack alignItems={"center"}>
          <Box sx={{ width: { md: "95%", xs: "100%" } }}>

            <Stack direction={{ xs: "column", md: "row" }} justifyContent={"space-between"} alignItems={"center"} gap={{ xs: "25px", md: "0px" }} mb={{ xs: 5, md: 15 }}>
              <Stack alignItems={"flex-start"} sx={{ width: { xs: "100%", md: "50%" } }}>
                <Typography sx={{ fontFamily: "Poppins", fontStyle: "bold", mb: 2,ml:{xs:2,md:0},mr:{xs:2,md:0}, fontSize: { xs: "30px", md: "50px", lg: "70px", xl: "90px" } }}>A CREATIVE WAY OF REACHING OUT TO THE AUDIENCE.</Typography>
              </Stack>
              <Box component={"img"} src={FirstImage} sx={{ width: { md: "50vw", xs: "100vw" }, borderRadius: { md: "50px", xs: "0px" } }} />
            </Stack>

            <Stack alignItems={"center"} sx={{ mb: {xs:0,md:5} }}>
              <Typography variant="h3" sx={{ color: "black", mb: 2, fontFamily: 'Poppins',borderBottom:"3px",borderBottomColor:"#16213E",borderBottomStyle:"solid",borderBottomRadius:"50px" }}>Our Services</Typography>
            </Stack>

            <Stack direction={{ xs: "column-reverse", md: "row" }} justifyContent={"space-between"} alignItems={"center"} gap={{ xs: "25px", md: "0px" }} mb={{ xs: 5, md: 15 }}>
            <Box component={"img"} src={SecondImage} sx={{ width: { md: "50vw", xs: "100vw" }, borderRadius: { md: "50px", xs: "0px" } }} />
            <Stack alignItems={"center"} justifyContent={"center"} sx={{ width: { xs: "100%", md: "50%" } }}>
                <Typography sx={{ fontFamily: "Poppins",ml:2, fontStyle: "bold", mb: 2, fontSize: { xs: "30px", md: "50px", lg: "70px", xl: "75px" } }}>Ad Film Script Writing</Typography>
                <Typography sx={{ fontFamily: "Poppins",ml:2,mr:2, textAlign: "justify"}}>Our Script Writer Have master performance on Ad film Concept. They know how to cover your total information under 30 Sec. We have professional script writer who write professional ad film story as per company brand and services.</Typography>
              </Stack>
            </Stack>

            <Stack direction={{ xs: "column", md: "row" }} justifyContent={"space-between"} alignItems={"center"} gap={{ xs: "25px", md: "0px" }} mb={{ xs: 5, md: 15 }}>
            <Stack alignItems={"center"} justifyContent={"center"} sx={{ width: { xs: "100%", md: "50%" } }}>
                <Typography sx={{ fontFamily: "Poppins", fontStyle: "bold", mb: 2,ml:{xs:2,md:0},mr:{xs:2,md:0}, fontSize: { xs: "25px", md: "50px", lg: "70px", xl: "80px" } }}>Ad Film Shooting on High Pro Cine Camera</Typography>
                <Typography sx={{ fontFamily: "Poppins",ml:2,mr:2, textAlign: "justify"}}>Professional Team of Cameraman, Light man, Makeup Artist. Super Wide Screen Cinema Scope Film Shooting Shooting on 2.35:1 Ratio We will shoot ad film on 2.35:1 Aspect Ratio. This is Full Screen Cinema Ratio for Cinema Movie Film Quality. Mostly National & Internation Ad film Shoots on this ratio.</Typography>
              </Stack>
            <Box component={"img"} src={ThirdImage} sx={{ width: { md: "50vw", xs: "100vw" }, borderRadius: { md: "50px", xs: "0px" } }} />
            </Stack>

            
            <Stack direction={{ xs: "column-reverse", md: "row" }} justifyContent={"space-between"} alignItems={"center"} gap={{ xs: "25px", md: "0px" }} mb={{ xs: 5, md: 15 }}>
            <Box component={"img"} src={FourthImage} sx={{ width: { md: "50vw", xs: "100vw" }, borderRadius: { md: "50px", xs: "0px" } }} />
            <Stack alignItems={"center"} justifyContent={"center"} sx={{ width: { xs: "100%", md: "50%" } }}>
                <Typography sx={{ fontFamily: "Poppins", fontStyle: "bold", ml:2, mb: 2, fontSize: { xs: "25px", md: "50px", lg: "70px", xl: "80px" } }}>Shooting Location Hunting Tour</Typography>
                <Typography sx={{ fontFamily: "Poppins",ml:2,mr:2, textAlign: "justify",ml:2}}>As per Ad subject we also provide indoor and outdoor location for your ad film shooting. Location will be changed as per subject of our Ad story script. Luxurious location makes your ad film more professional. What we will provide location its will be included with shooting permission also.</Typography>
              </Stack>
            </Stack>

            <Stack direction={{ xs: "column", md: "row" }} justifyContent={"space-between"} alignItems={"center"} gap={{ xs: "25px", md: "0px" }} mb={{ xs: 5, md: 15 }}>
            <Stack alignItems={"center"} justifyContent={"center"} sx={{ width: { xs: "100%", md: "50%" } }}>
                <Typography sx={{ fontFamily: "Poppins", fontStyle: "bold", mb: 2, fontSize: { xs: "25px", md: "50px", lg: "70px", xl: "80px" } }}>SOCIAL MEDIA POST DESIGN</Typography>
                <Typography sx={{ fontFamily: "Poppins",ml:2,mr:2, textAlign: "justify"}}>Professional new social media post designs layout creative graphics inspiration templates unique ideas! web business and advertising design ...</Typography>
              </Stack>
            <Box component={"img"} src={FivethImage} sx={{ width: { md: "50vw", xs: "100vw" }, borderRadius: { md: "50px", xs: "0px" } }} />
            </Stack>

          </Box>
        </Stack>
      </Box>

      <ScrollTop {...props}>
        <Fab size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
      <Footer />
    </>
  );
}

export default Advertisement;