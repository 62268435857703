import AppBarCommon from "../AppBarCommon";
import { Stack, Typography, Box, Paper, Card, Button, IconButton } from "@mui/material";
import web1 from "../img/web1.jpg"
import birthdaypic1 from '../img/birthdaypic/pic1.jpg'
import birthdaypic2 from '../img/birthdaypic/pic2.jpg' 
import birthdaypic3 from '../img/birthdaypic/pic3.jpg' 
import birthdaypic4 from '../img/birthdaypic/pic4.jpg' 
import birthdaypic5 from '../img/birthdaypic/pic5.jpg' 
import birthdaypic6 from '../img/birthdaypic/pic6.jpg' 
import { useState } from "react";


export default function Gallery() {

    const allimage = [web1, web1, web1, web1, web1, web1, web1, web1, web1, web1]
    const weddingimage = [web1, web1, web1]
    const birthdayimage = [birthdaypic3, birthdaypic4, birthdaypic5, birthdaypic6]
    const cateringimage = [web1, web1, web1]
    const entertainmentimage = [web1]

    const [image, setImage] = useState(allimage)
    const [bg, setBg] = useState(0)



    const btn = [{
        name: "All", click: () => {
            setImage(allimage)
            setBg(0)
        }
    },
    {
        name: "Wedding", click: () => {
            setImage(weddingimage)
            setBg(1)
        }
    },
    {
        name: "Birthday", click: () => {
            setImage(birthdayimage)
            setBg(2)
        }
    },
    {
        name: "Portfolio", click: () => {
            setImage(cateringimage)
            setBg(3)
        }
    },
    {
        name: "Makeup", click: () => {
            setImage(entertainmentimage)
            setBg(4)
        }
    }
    ]



    return (
        <>
            <AppBarCommon value={4} topArrow={"back-to-top-anchor"} />
            <Stack alignItems={"center"} sx={{ mt: 10 }}>
                <Box sx={{ width: "90%" }}>
                    <Typography variant="h1" sx={{ textAlign: "center", color: "#16213E" }}>Gallery</Typography>

                    <Stack direction={"row"} justifyContent={"center"} mt={5} gap={5} flexWrap={"wrap"}>
                        {btn.map((items, index) => (
                            <Button variant="contained" onClick={items.click} sx={{ bgcolor: index === bg ? "#16213E" : "transparent", color: index === bg ? "white" : "black", border: "2px solid #16213E", borderRadius: "50px", ":hover": { bgcolor: "#16213E", color: "white" } }}>{items.name}</Button>
                        ))}
                    </Stack>

                    <Stack direction={"row"} justifyContent={"center"} mt={5} gap={5} flexWrap={"wrap"}>
                        {image.map((items) => (
                            <Box component={"img"} src={items} sx={{ width: { xs: "100%", md: "20%" }, height: { xs: "100%", md: "20%" } }} />
                        ))}
                    </Stack>
                </Box>
            </Stack>
        </>
    )
}