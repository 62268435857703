import { Stack, IconButton, Typography, Box, Paper, Card, Button } from "@mui/material";
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useNavigate } from "react-router-dom";



function Footer() {
  let navigate = useNavigate();
  const footer = [{iconname:<FacebookIcon sx={{ color: "white" }}/>, click:()=>window.open("https://www.facebook.com/faizstudios")},
  {iconname:<WhatsAppIcon sx={{ color: "white" }} />, click:()=>window.open("https://wa.me/message/IUDIA6SA6M7OH1")},
 {iconname: <InstagramIcon sx={{ color: "white" }} />, click:()=>window.open("https://www.instagram.com/faiz_studios/")},
 {iconname: <TwitterIcon sx={{ color: "white" }} />, click:()=>window.open("https://www.twitter.com")}
  ]


  const footer2 = [
    [{
      icons: <NavigateNextIcon sx={{ mr: 1, color: "white" }} />,
      content: "Home",
      btn: () => navigate("/")
    },{
      icons: <NavigateNextIcon sx={{ mr: 1, color: "white" }} />,
      content: "Wedding",
      btn: () => navigate("/wedding")
    },
    {
      icons: <NavigateNextIcon sx={{ mr: 1, color: "white" }} />,
      content: "Advertisement",
      btn: () => navigate("/advertisement")
    },
    {
      icons: <NavigateNextIcon sx={{ mr: 1, color: "white" }} />,
      content: "Digital Marketing",
      btn: () => navigate("/digitalmarketing")
    },
    {
      icons: <NavigateNextIcon sx={{ mr: 1, color: "white" }} />,
      content: "Gallery",
      btn: () => navigate("/gallery")
    }],

    [{
      icons: <LocationOnIcon sx={{ mr: 1, color: "white", cursor:"pointer"}} onClick={()=>window.open("https://www.google.com/maps/dir/8.6915879,77.7337515/faiz+studios/@8.6983981,77.7225358,18z/data=!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3b041327e602e27f:0x2ec5312b62cff1b0!2m2!1d77.7223742!2d8.6990983")} />,
      content: "48A/1A 1st Floor Kamarajar Salai opposite RC Church, Xavier Colony,Tirunelveli - 627005",
      click:()=>window.open("https://goo.gl/maps/wZBihExFoHfvex6W9")
    },
    {
      icons: <EmailIcon sx={{ mr: 1, color: "white", cursor:"pointer"}} onClick={()=>window.open("mailto:faizstudio.in@gmail.com")}  />,
      content: "faizstudio.in@gmail.com",
      click:()=>window.open("mailto:faizstudio.in@gmail.com")
    },
    {
      icons: <PhoneIphoneIcon sx={{ mr: 1, color: "white", cursor:"pointer"}} onClick={()=>window.open("tel:6383347070")}  />,
      content: "6383347070",
      click:()=>window.open("tel:6383347070")
    }]
  ]

  return (
    <>
    
      <Box style={{ backgroundColor: "#16213E" }}>
        <Stack direction={"row"} justifyContent={"space-between"} sx={{ mb: 7, p: 8 }} flexWrap={"wrap"} gap={3}>
          <div>
            <Typography variant="h6" sx={{ mb: 1, color: "white", textAlign: "center" }}>Connect WIth Us</Typography>
            <Stack direction={"row"} justifyContent={"space-between"}>
              {footer.map((text) => (<IconButton onClick={text.click}>{text.iconname}</IconButton>))}
            </Stack>
          </div>
          <div>
            <Typography variant="h6" mb={2} sx={{ color: "white" }}>Site Maps</Typography>
            {footer2[0].map((text) => (
              <Stack direction={"row"} alignItems={"center"} mb={2}>
                <div>{text.icons}</div>
                <Typography onClick={text.btn} sx={{ color: "white", cursor: "pointer" }}>{text.content}</Typography>
              </Stack>
            ))}
          </div>
          <div>
            <Typography variant="h6" mb={2} sx={{ color: "white" }}>Office Address</Typography>
            {footer2[1].map((text) => (
              <Stack direction={"row"} alignItems={"center"} mb={3}>
                <div>{text.icons}</div>
                <Typography onClick={text.click} sx={{ color: "white", cursor: "pointer" }}>{text.content}</Typography>
              </Stack>
            ))}
          </div>
        </Stack>
        <Typography onClick={()=>window.open("https://webbot.in/")} sx={{ textAlign: "center", color: "white", cursor:"pointer"}}>Designed & Developed by Webbot.in</Typography>
      </Box>
    </>
  )
}

export default Footer;