import './weddindstyle.css'
import { Stack, Typography, Box, Card } from "@mui/material";
import FirstImage from "../../img/weddingpageimg1.svg"
import SecondImage from "../../img/weddingpageimg2.svg"
import ThirdImage from "../../img/weddingpageimg7.svg"
import FourthImage from "../../img/weddingpageimg3.svg"
import FivethImage from "../../img/weddingpageimg4.svg"
import SixthImage from "../../img/weddingpageimg5.svg"
import Seventh from "../../img/weddingpageimg6.svg"

export default function CardFlip() {
    const services = [{ name: "Photography", img: SecondImage, bkname:"About", content:"this is faizal studio"}, { name: "Entertainment", img: SixthImage, bkname:"About", content:"this is faizal studio" }, { name: "Makeup", img: FivethImage, bkname:"About", content:"this is faizal studio" }, { name: "Catering", img: FourthImage, bkname:"About", content:"this is faizal studio" }, { name: "Decoration", img: ThirdImage, bkname:"About", content:"this is faizal studio" }, { name: "Surprise", img: Seventh , bkname:"About", content:"this is faizal studio"}]
    return (
        <>
         <Stack direction={{ xs: "column", sm: "row" }} justifyContent={"center"} alignItems={"center"} gap={{ xs: "25px", md: "85px" }} sx={{ mb: 10 }} flexWrap={"wrap"}>
           {services.map((items, index)=>(
            <Box>
               <Box class="card">
             <Card class="front">
               <Stack direction={"column"} alignItems={"center"} justifyContent={"space-between"} sx={{ height: "100%" }}>
                 <Box component={"img"} src={items.img} sx={{width: "100%", mt: index===4 ? 7 : 3}}>
                 </Box>
               </Stack>
             </Card>

             <Card class="back">
               <Stack direction={"column"} alignItems={"center"} >
                 <Typography variant="h6" sx={{ fontFamily: 'Poppins' }}>{items.bkname}</Typography>
                 <Typography variant="h6" sx={{ fontFamily: 'Poppins' }}>{items.content}</Typography>
               </Stack>
             </Card>
           </Box>
            <Typography variant="h6" sx={{textAlign: 'center', bgcolor:"#F5EDDC", borderBottomLeftRadius:"15px", borderBottomRightRadius:"15px"}}> {items.name} </Typography>
            </Box>
           ))}
          
            </Stack>
        </>
    )
}