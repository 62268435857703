import AppBarCommon from "../AppBarCommon";
import { Stack, Typography, Box, Card } from "@mui/material";
import CountUp from "react-countup";
import FirstImage from "../img/marketingpageimg1.svg"
import SecondImage from "../img/marketingpageimg2.svg"
import ThirdImage from "../img/marketingpageimg3.svg"
import FourthImage from "../img/marketingpageimg4.svg"
import FivethImage from "../img/marketingpageimg5.svg"
import SixthImage from "../img/marketingpageimg6.svg"
import Footer from "../Footer";
import Fade from '@mui/material/Fade';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fab from '@mui/material/Fab';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';


function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
        top: -50, behavior: 'smooth',
      });
    }
  };


  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', right:{xs:35, md:130}, top:{xs:"83vh", md:"92.5vh"} }}
      >
        {children}
      </Box>
    </Fade>
  );
}

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function DigitalMarketing(props) {



  const counts = [{
    count: 100,
    name: "Markets"
  }, {
    count: 90,
    name: "No of Clients"
  }, {
    count: 50,
    name: "Consultants"
  }]

  const services = [{ name: "Serach Engine Optimization", img: ThirdImage }, { name: "Social Media Advertising", img: FourthImage }, { name: "Public Relations", img: FivethImage }, { name: "Reputation Management", img: SixthImage }]
  return (
    <>
      <AppBarCommon value={3} topArrow={"back-to-top-anchor"} />
      <Box sx={{ mt: "50px" }}>


        <Stack alignItems={"center"}>
          <Box sx={{ width: { md: "95%", xs: "100%" } }}>

            <Stack direction={{ xs: "column", md: "row" }} justifyContent={"space-between"} alignItems={"center"} gap={{ xs: "25px", md: "0px" }} mb={{ xs: 5, md: 15 }}>
              <Stack alignItems={"center"} sx={{ width: { xs: "100%", md: "50%" } }}>
                <Typography sx={{ fontFamily: "Poppins", fontStyle: "bold", mb: 2,ml:2, fontSize: { xs: "35px", md: "50px", lg: "70px", xl: "100px" } }}>WE ARE DIGITAL MARKETING EXPERT</Typography>
                <Typography sx={{ fontFamily: "Poppins", textAlign: "justify", mr: 2,ml:2,mr:2}}>Your business is growing but not fast enough. you struggle to find a way to capture every last lead and sale available online. As a result, you feel frustrated and stuck.</Typography>
              </Stack>
              <Box component={"img"} src={FirstImage} sx={{ width: { md: "50vw", xs: "100vw" }, borderRadius: { md: "50px", xs: "0px" } }} />
            </Stack>

            <Stack direction={{ xs: "column", md: "row-reverse" }} justifyContent={"space-between"} alignItems={"center"} gap={{ xs: "25px", md: "0px" }} mb={10}>
              <Box>
                <Typography sx={{ fontFamily: "Poppins", fontStyle: "bold", ml: 2,mr:2, mb: 2, fontSize: { xs: "30px", md: "35px", lg: "55px", xl: "65px" } }}>Your outsourced digital marketing A-Team to "Catch 'em all".</Typography>
                <Typography sx={{ fontFamily: "Poppins", textAlign: "justify", ml: 2,mr:2, mb: 2 }}>Meet Faiz Studios. We believe no opportunity should be left on the table. Our vision is to serve as a powerful extensionof your own marketing department, with no overhead or long-term contract, managed by our team of experts delivering you digital greatness</Typography>
                <Stack direction={{ xs: "column", sm: "row" }} justifyContent={"space-around"} gap={{ xs: "25px", md: "0px" }}>
                  {counts.map((e) => (
                    <Stack alignItems={"center"}>
                      <CountUp end={e.count} duration={5} suffix=" +" style={{ fontFamily: "Poppins", fontSize: "50px" }} />
                      <Typography style={{ fontFamily: "Poppins", fontSize: "25px" }}>{e.name}</Typography>
                    </Stack>
                  ))}
                </Stack>
              </Box>
              <Box component={"img"} src={SecondImage} sx={{ width: { md: "50vw", xs: "100vw" } }} />
            </Stack>

            <Stack alignItems={"center"} sx={{ mb: 10 }}>
              <Typography variant="h3" sx={{ color: "black", mb: 2, fontFamily: 'Poppins',borderBottom:"3px",borderBottomColor:"#16213E",borderBottomStyle:"solid",borderBottomRadius:"50px"  }}>Our Services</Typography>
              <Typography variant="body2" sx={{ color: "black", fontFamily: 'Poppins', textAlign: "justify", ml: 2,mr:2, mr: 1 }}>For over a decade, Faiz Studio has combined agile cutting-edge strategies with world-class, in-house talent to get your brand the attention it deserves it deserves online</Typography>
            </Stack>

            <Stack direction={{ xs: "column", md: "row" }} justifyContent={"space-between"} gap={{ xs: "25px", md: "0px" }} sx={{ mb: 10 }}>
              {services.map((e) => (
                <Card sx={{ width: { xs: "100%", md: "20%" }, borderRadius: "15px" }} elevation={5}>
                  <Stack direction={"column"} alignItems={"center"} justifyContent={"space-between"} sx={{ height: "100%" }}>
                    <Typography variant="h6" sx={{ fontFamily: 'Poppins' }}>{e.name}</Typography>
                    <Box component={"img"} src={e.img} sx={{ width: "100%", mt: 3 }}>
                    </Box>
                  </Stack>
                </Card>
              ))}
            </Stack>
          </Box>
        </Stack>
      </Box>

      <ScrollTop {...props}>
        <Fab size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
      <Footer />
    </>
  );
}

export default DigitalMarketing;