import AppBarCommon from "../AppBarCommon";
import { TextField, Button, MenuItem, Menu, Paper, Avatar, Tooltip, InputBase, IconButton, Typography, Card, DialogContent, Dialog, DialogTitle, DialogActions, Stack, Pagination } from "@mui/material";


function Users() {

  return (
    <>
      <div>
        <AppBarCommon />
        <Stack alignItems={"center"} justifyContent={"center"} sx={{ height: "100vh" }}>
          <Typography variant="h2">Doc Types</Typography>
        </Stack>
      </div>
    </>
  );
}

export default Users;