import Users from "./pages/Users";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Advertisement from "./pages/Advertisement";
import Wedding from "./pages/Wedding";
import DigitalMarketing from "./pages/DigitalMarketing";
import Gallery from "./pages/Gallery";

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<Home />} ></Route>
      <Route exact path="/wedding" element={<Wedding />} ></Route>
      <Route exact path="/advertisement" element={<Advertisement />} ></Route>
      <Route exact path="/digitalmarketing" element={<DigitalMarketing />} ></Route>
      <Route exact path="/gallery" element={<Gallery />} ></Route>
      <Route exact path="/findox" element={<Users />} ></Route>
    </Routes>
  </BrowserRouter>
  );
}

export default App;
