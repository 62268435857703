import { Button, Typography, Box, IconButton, Stack, Drawer, Divider, Card, Dialog, DialogTitle, ListItem, ListItemButton, List, ListItemText, } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import React, { useEffect, useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Logo from "../src/img/logo.png"
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function PaperComponent(props) {
  return <Card {...props} style={{ width: "90%" }} />;
}


function AppBarCommon(props) {
  let navigate = useNavigate();
  const theme = useTheme();
  const drawerWidth = 240;

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    }),
  }));

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  }));

  const [hide, setHide] = useState(false)

  const handleDrawerOpen = () => {
    setOpen(true);
    setHide(true)
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setHide(false)
  };



  const [open, setOpen] = useState(false);



  const navItems = [{
    "name": "Home",
    "redirectPage": () => {
      navigate("/")
      handleDrawerClose()
    },

  }, {
    "name": "Wedding",
    "redirectPage": () => {
      navigate("/wedding")
      handleDrawerClose()
    },

  }, {
    "name": "Advertisement",
    "redirectPage": () => {
      navigate("/advertisement")
      handleDrawerClose()
    },

  },
  {
    "name": "Digital Marketing",
    "redirectPage": () => {
      navigate("/digitalmarketing")
      handleDrawerClose()
    },
  },{
    "name": "Gallery",
    "redirectPage": () => {
      navigate("/gallery")
      handleDrawerClose()
    },
  }]

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, []);


  const [openContact, setOpenContact] = useState(false);

  const handleCloseContact = () => {
    setOpenContact(false);
  };

  const contactAddress = [{
    icons: <LocationOnIcon sx={{ mr: 1, color: "white", cursor: "pointer" }} onClick={() => window.open("https://www.google.com/maps/dir/8.6915879,77.7337515/faiz+studios/@8.6983981,77.7225358,18z/data=!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3b041327e602e27f:0x2ec5312b62cff1b0!2m2!1d77.7223742!2d8.6990983")} />,
    content: "48A/1A 1st Floor Kamarajar Salai opposite RC Church, Xavier Colony,Tirunelveli - 627005",
    click: () => window.open("https://goo.gl/maps/wZBihExFoHfvex6W9")
  },
  {
    icons: <EmailIcon sx={{ mr: 1, color: "white", cursor: "pointer" }} onClick={() => window.open("mailto:faizstudio.in@gmail.com")} />,
    content: "faizstudio.in@gmail.com",
    click: () => window.open("mailto:faizstudio.in@gmail.com")
  },
  {
    icons: <PhoneIphoneIcon sx={{ mr: 1, color: "white", cursor: "pointer" }} onClick={() => window.open("tel:6383347070")} />,
    content: "6383347070",
    click: () => window.open("tel:6383347070")
  }]

  const footer = [{ iconname: <FacebookIcon sx={{ color: "white" }} />, click: () => window.open("https://www.facebook.com/faizstudios") },
  { iconname: <WhatsAppIcon sx={{ color: "white" }} />, click: () => window.open("https://wa.me/message/IUDIA6SA6M7OH1") },
  { iconname: <InstagramIcon sx={{ color: "white" }} />, click: () => window.open("https://www.instagram.com/faiz_studios/") },
  { iconname: <TwitterIcon sx={{ color: "white" }} />, click: () => window.open("https://www.twitter.com") }
  ]

  const tawkFunction = () => {
    var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/63220a7337898912e96929fe/1gcufhl4l';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
  }

  return (
    <div>
      <AppBar sx={{ bgcolor: "transparent", position: "sticky" }} open={open} elevation={0} id={props.topArrow}>
        <Stack alignItems={"center"} sx={{ mt: 2 }}>
          <Box sx={{ width: "95%" }}>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} >
              <Box sx={{ width: "20%"}}>
                <Box component={"img"} src={Logo} onClick={() => navigate("/")} sx={{ width: "100px", cursor: "pointer" }} />
              </Box>

              <Box sx={{ display: { xs: 'none', md: 'block', } }}>
                <Stack direction={"row"} alignItems={"center"} gap={5}>
                  <Stack direction={"row"}>
                    {navItems.map((item, index) => (
                      <Typography onClick={item.redirectPage} sx={{ color: "#87899E", cursor: "pointer", ml: 5, borderBottom: index === props.value ? '2px' : false, borderBottomColor: index === props.value ? 'red' : false, borderBottomStyle: index === props.value ? 'solid' : false }}>
                        {item.name}
                      </Typography>
                    ))}
                  </Stack>
                  <Button variant="contained" onClick={() => setOpenContact(true)} sx={{ display: { xs: 'none', md: 'block' }, color: "white", bgcolor: "black", ":hover": { bgcolor: "#16213E" }, borderRadius: "50px", cursor: "pointer" }} >Contact us</Button>
                </Stack>

              </Box>
              <IconButton color="inherit" aria-label="open drawer" edge="start" sx={{ color: "black", display: { xs: hide ? "none" : "block", md: 'none' } }} onClick={handleDrawerOpen}>
                <MenuIcon />
              </IconButton>


            </Stack>
          </Box>
        </Stack>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Stack justifyContent={"flex-end"} alignItems={"center"}>
          <List>
            {navItems.map((item, i) => (
              <ListItem key={item} disablePadding>
                <ListItemButton
                  onClick={item.redirectPage}
                >
                  <ListItemText primary={item.name} sx={{ fontFamily: "Poppins" }} />
                </ListItemButton>
              </ListItem>
            ))}

            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  setOpenContact(true)
                  handleDrawerClose()
                }}
              >
                <ListItemText primary={"Contact Us"} sx={{ fontFamily: "Poppins"}} />
              </ListItemButton>
            </ListItem>
          </List>
        </Stack>
      </Drawer>


      <Dialog open={openContact} onClose={handleCloseContact} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title">
        <Box sx={{ bgcolor: "#16213E" }}>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3943.9204605778177!2d77.72018551525427!3d8.699103596462544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b041327e602e27f%3A0x2ec5312b62cff1b0!2sFaiz%20Studios!5e0!3m2!1sen!2sin!4v1663260253849!5m2!1sen!2sin" style={{ width: "100%", height: "300px", marginBottom: "15px" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          <Stack alignItems={"center"} >
            <Box sx={{ ml: 2, mr: 2 }}>
              <Typography variant="h5" mb={2} sx={{ color: "white" }}>Office Address</Typography>
              {contactAddress.map((text) => (
                <Stack direction={"row"} alignItems={"center"} mb={3}>
                  <div>{text.icons}</div>
                  <Typography onClick={text.click} sx={{ color: "white", cursor: "pointer" }}>{text.content}</Typography>
                </Stack>
              ))}

              <Typography variant="h5" sx={{ mb: 1, color: "white", mt: 5 }}>Connect With Us</Typography>
              <Stack direction={"row"} justifyContent={"space-between"} sx={{ width: "30%" }}>
                {footer.map((text) => (<IconButton onClick={text.click}>{text.iconname}</IconButton>))}
              </Stack>
            </Box>
          </Stack>

          <Stack alignItems={"center"}><Button variant="contained" onClick={handleCloseContact} sx={{ bgcolor: "#C21010", mb: 1, mr: 2, mt: 6, borderRadius: "50px", ":hover": { bgcolor: "#16213E", border: "#D2001A solid 2px", borderRadius: "50px" } }}>Close</Button></Stack>
        </Box>


      </Dialog>

    </div>
  )
}

export default AppBarCommon