import AppBarCommon from "../AppBarCommon";
import Footer from "../Footer"
import { Stack, Typography, Box, Avatar, Paper, Card, Button, IconButton } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import web1 from "../img/web1.jpg"
import studiopic from '../img/studiopic.jpg'
import web11 from "../img/web11.jpg"
import faizpic from '../img/faizpic.jpg'
import weddingserviceimg from "../img/weddingserviceimg.svg"
import advertisementserviceimg from "../img/advertisementserviceimg.svg"
import marketingserviceimg from "../img/marketingserviceimg.svg"
import { useNavigate } from "react-router-dom";
import Fade from '@mui/material/Fade';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fab from '@mui/material/Fab';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';
import CountUp from "react-countup";


function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
        top: -50, behavior: 'smooth',
      });
    }
  };


  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', right: { xs: 35, md: 130 }, top: { xs: "83vh", md: "92.5vh" } }}
      >
        {children}
      </Box>
    </Fade>
  );
}

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};



function Home(props) {
  let navigate = useNavigate();

  const services = [{
    name: "Wedding",
    btn: () => navigate("/wedding"),
    img: weddingserviceimg
  }, {
    name: "Advertisement",
    btn: () => navigate("/advertisement"),
    img: advertisementserviceimg
  }, {
    name: "Marketing",
    btn: () => navigate("/digitalmarketing"),
    img: marketingserviceimg
  }]


  const counts = [{
    count: 200,
    name: "Complete Projects"
  }, {
    count: 60,
    name: "No of Clients"
  }, {
    count: 12,
    name: "Employees"
  }, {
    count: 2,
    name: "Year of Experience"
  }]


  //   const footer = [<FacebookIcon sx={{ color: "white"}} />,
  //   <LinkedInIcon sx={{ color: "white" }} />,
  //   <InstagramIcon sx={{  color: "white"}} />,
  //   <TwitterIcon sx={{  color: "white"}} />
  //   ]

  // const [display, setdisplay] = useState("block")
  // const changedisplay = () => {(window.scrollY >= 750) ?  setdisplay("none") :  setdisplay("block")}
  // window.addEventListener("scroll", changedisplay)

  const heights = "460px"

  const aboutcontent = "Our team knows that your wedding day is one of the most important days in your life, and that you'd like the memories to be captured in the most beautiful way. The photographers and videographers in our team are dedicated to creating the most beautiful memories of your wedding day. In Photography service we are expertise in wedding photography, baby photography, corporate photography and ads for business. We specialize in wedding photography with a team of in-house photographers, editors, and designers. While our photographic style may vary from assignment to assignment, our commitment to quality and craftsmanship remains unchanged."

  return (
    <>
      <AppBarCommon value={0} topArrow={"back-to-top-anchor"} />
      <Box sx={{ mt: "10px" }}>
        <Box sx={{ mb: 5, display: { xs: "none", md: "block" } }}>
          <Carousel showArrows={false} showThumbs={false} infiniteLoop={true} autoPlay={true} interval={3000} showStatus={false} stopOnHover={false} autoFocus={true} >
            <div>
              <Box component={"img"} src={web1} sx={{ height: { xs: "60vh", md: "900px" } }} />
            </div>
            <div>
              <Box component={"img"} src={web1} sx={{ height: { xs: "60vh", md: "900px" } }} />
            </div>
            <div>
              <Box component={"img"} src={web1} sx={{ height: { xs: "60vh", md: "900px" } }} />
            </div>
            <div>
              <Box component={"img"} src={web1} sx={{ height: { xs: "60vh", md: "900px" } }} />
            </div>
            <div>
              <Box component={"img"} src={web1} sx={{ height: { xs: "60vh", md: "900px" } }} />
            </div>
          </Carousel>
        </Box>

        <Box sx={{ mb: 5, display: { xs: "block", md: "none" } }}>
          <Carousel showArrows={false} showThumbs={false} infiniteLoop={true} autoPlay={true} interval={3000} showStatus={false} stopOnHover={false} autoFocus={true} >
            <div>
              <Box component={"img"} src={web11} sx={{ height: { xs: heights, md: "90vh" } }} />
            </div>
            <div>
              <Box component={"img"} src={web11} sx={{ height: { xs: heights, md: "90vh" } }} />
            </div>
            <div>
              <Box component={"img"} src={web11} sx={{ height: { xs: heights, md: "90vh" } }} />
            </div>
            <div>
              <Box component={"img"} src={web11} sx={{ height: { xs: heights, md: "90vh" } }} />
            </div>
            <div>
              <Box component={"img"} src={web11} sx={{ height: { xs: heights, md: "90vh" } }} />
            </div>
          </Carousel>
        </Box>


        <Box sx={{ mb: 2 }}>

          <Stack alignItems={"center"} sx={{ mb: 5 }}>
            <Typography variant="h3" sx={{ color: "black", mb: 2, fontFamily: 'Poppins' }}>Our Services</Typography>
            <Typography variant="body2" sx={{ color: "black", fontFamily: 'Poppins', textAlign: "justify", ml: 1, mr: 1 }}>Full-Spectrum Service Offerings To Make Your Business Into The Next Stage</Typography>
          </Stack>


          <Stack alignItems={"center"} sx={{ mb: 10 }}>
            <Box sx={{ width: { md: "70%", xs: "90%" } }}>
              <Stack direction={{ xs: "column", md: "row" }} justifyContent={"space-between"} gap={{ xs: "55px", md: "0px" }} sx={{ mb: 10 }}>
                {services.map((e) => (
                  <Card onClick={e.btn} elevation={5} sx={{ cursor: "pointer", width: { xs: "100%", md: "25%", lg: "20%" }, borderRadius: "15px", bgcolor: "#F5EDDC", ":hover": { transform: "scale(1.1)" } }}>
                    <Stack direction={"column"} alignItems={"center"} justifyContent={"space-between"} sx={{ height: "100%" }}>
                      <Typography variant="h6" sx={{ fontFamily: 'Poppins', mb: 5, mt: 2 }}>{e.name}</Typography>
                      <Box component={"img"} src={e.img} sx={{ width: "100%" }} />
                    </Stack>
                  </Card>
                ))}
              </Stack>




            </Box>
          </Stack>

          <Stack alignItems={"center"} sx={{ width: "100%" }}>
            <Box sx={{ width: { xs: "100%", lg: "80%" } }}>
              <Stack direction={{ xs: "column", sm: "row" }} justifyContent={"space-around"} gap={{ xs: "25px", md: "0px" }}>
                {counts.map((e) => (
                  <Stack alignItems={"center"}>
                    <CountUp enableScrollSpy={true} end={e.count} duration={1} suffix=" +" style={{ fontFamily: "Poppins", fontSize: "50px" }} />
                    <Typography style={{ fontFamily: "Poppins", fontSize: "25px" }}>{e.name}</Typography>
                  </Stack>
                ))}
              </Stack>
            </Box>
          </Stack>


          <Box mt={10}>
            <Typography variant="h3" sx={{ color: "black", mb: 2, fontFamily: 'Poppins', textAlign: "center" }}>About Us</Typography>
            <Typography variant="h6" sx={{ color: "black", mb: 2, fontFamily: 'Poppins', textAlign: "center", width: "98%" }}>Welcome to Faiz Studios and Event Management – Event Planner in Tirunelveli</Typography>
            <Stack alignItems={"center"}>
              <Box width={{ xs: "95%", md: "56%" }}>
                <div >
                  <Box
                    component={"img"}
                    src={studiopic}
                    alt={"sample"}
                    sx={{ width: "100%", height: "50vh", borderRadius: "15px" }}
                  />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Avatar
                      alt="pic"
                      src={faizpic}
                      sx={{ width: 156, height: 156, position: "relative", bottom: "65px"}}
                    />
                    
                  </div>
                </div>

                <Typography variant="h6" sx={{ mb: 2, fontFamily: 'Poppins', textAlign: "justify" }} >{aboutcontent}</Typography>
              </Box>
            </Stack>
          </Box>

        </Box>
      </Box>

      <ScrollTop {...props}>
        <Fab size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
      <Footer />
      {/* 
    <Box sx={{position:"fixed", top:"50vh",left:"10px"}}>
      <Stack direction={"column"}>
      {footer.map((text) => (<IconButton sx={{bgcolor:"black", borderRadius:"30px",mb:2, display:{xs:"none", md:display}}}>{text}</IconButton>))}
      </Stack>
    </Box> */}
    </>
  );
}

export default Home;