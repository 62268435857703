import AppBarCommon from "../AppBarCommon";
import { Stack, Typography, Box, Card, Divider, Skeleton, IconButton } from "@mui/material";
import FirstImage from "../img/weddingpageimg1.svg"
import SecondImage from "../img/weddingpageimg2.svg"
import ThirdImage from "../img/weddingpageimg7.svg"
import FourthImage from "../img/weddingpageimg3.svg"
import FivethImage from "../img/weddingpageimg4.svg"
import SixthImage from "../img/weddingpageimg5.svg"
import Seventh from "../img/weddingpageimg6.svg"
import { Carousel } from 'react-responsive-carousel';
import ReactPlayer from 'react-player'
import CountUp from "react-countup";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Fade from '@mui/material/Fade';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fab from '@mui/material/Fab';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';
import Footer from "../Footer"
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import web1 from "../img/web1.jpg"
import { useEffect } from "react";
import axios from 'axios';
import CardFlip from "./weddingcardflip/CardFlip";



function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
        top: -50, behavior: 'smooth',
      });
    }
  };


  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', right: { xs: 35, md: 130 }, top: { xs: "83vh", md: "92.5vh" } }}
      >
        {children}
      </Box>
    </Fade>
  );
}

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};


// loading
function Media(props) {
  const { loading = false } = props;
  const youtubevideourl = ["https://www.youtube.com/watch?v=e-1EV6amJQU", "https://www.youtube.com/watch?v=empdypFTLaA", "https://www.youtube.com/watch?v=6RZ3vF8SORQ", "https://www.youtube.com/watch?v=5P6O1oT8Mz8", "https://www.youtube.com/watch?v=e-1EV6amJQU", "https://www.youtube.com/watch?v=empdypFTLaA"]

  return (
    <Stack direction={{ xs: "column", md: "row" }} justifyContent={"space-between"} gap={{ xs: "25px", md: "35px" }} sx={{ mb: 10 }} flexWrap={"wrap"}>
      {youtubevideourl.map((e) => (
        <Card sx={{ width: { xs: "100%", md: "30%" }, borderRadius: "15px" }} elevation={5}>
          {loading ?
            <ReactPlayer
              url={e}
              volume='1'
              muted
              playing={true}
            /> : <Skeleton variant="rectangular" />
          }
        </Card>
      ))}
    </Stack>
  );
}

Media.propTypes = {
  loading: PropTypes.bool,
};







function Wedding(props) {
  const services = [{ name: "Photography", img: SecondImage }, { name: "Entertainment", img: SixthImage }, { name: "Makeup", img: FivethImage }, { name: "Catering", img: FourthImage }, { name: "Decoration", img: ThirdImage }, { name: "Surprise", img: Seventh }]
  const counts = [{
    count: 60,
    name: "Complete Projects"
  }, {
    count: 60,
    name: "No of Clients"
  }, {
    count: 12,
    name: "Employees"
  }, {
    count: 2,
    name: "Year of Experience"
  }]
  const youtubevideourl = ["https://www.youtube.com/watch?v=e-1EV6amJQU", "https://www.youtube.com/watch?v=empdypFTLaA", "https://www.youtube.com/watch?v=6RZ3vF8SORQ", "https://www.youtube.com/watch?v=5P6O1oT8Mz8", "https://www.youtube.com/watch?v=e-1EV6amJQU", "https://www.youtube.com/watch?v=empdypFTLaA"]

  const footer = [<FacebookIcon sx={{ color: "white" }} />,
  <LinkedInIcon sx={{ color: "white" }} />,
  <InstagramIcon sx={{ color: "white" }} />,
  <TwitterIcon sx={{ color: "white" }} />
  ]

  const heights = "500px"

  return (
    <>
      <AppBarCommon value={1} topArrow={"back-to-top-anchor"} />
      <Box sx={{ mt: "10px" }}>
        <Stack alignItems={"center"}>
          <Box sx={{ width: { md: "95%", xs: "100%" } }}>

            <Stack direction={{ xs: "column", md: "row" }} justifyContent={"space-between"} alignItems={"center"} gap={{ xs: "25px", md: "0px" }} mb={{ xs: 5, md: 15 }}>
              <Stack alignItems={"center"} sx={{ width: { xs: "100%", md: "50%" } }}>
                <Typography sx={{ fontFamily: "Poppins", fontStyle: "bold", mb: 2, ml: 1, mr: 1, fontSize: { xs: "30px", md: "50px", lg: "70px", xl: "90px" } }}>WE WILL ORGANIZE YOUR WEDDING WHILE YOU ARE CHILLING</Typography>
                <Typography sx={{ fontFamily: "Poppins", textAlign: "justify", mr: 2, ml: 1, mr: 1, }}>We are a team of professionals with a lot of experience. we will take into all your wishes, that you will be definitely satisfied.</Typography>
              </Stack>
              <Box component={"img"} src={FirstImage} sx={{ width: { md: "50vw", xs: "100vw" }, borderRadius: { md: "50px", xs: "0px" } }} />
            </Stack>


            <Stack alignItems={"center"} sx={{ mb: 10 }}>
              <Typography variant="h3" sx={{ color: "black", mb: 2, fontFamily: 'Poppins', borderBottom: "3px", borderBottomColor: "#16213E", borderBottomStyle: "solid", borderBottomRadius: "50px" }}>Our Services</Typography>
              {/* <Typography variant="body2" sx={{ color: "black", fontFamily: 'Poppins', textAlign: "justify", ml: 1, mr: 1 }}>For over a decade, Faiz Studio has combined agile cutting-edge strategies with world-class, in-house talent to get your brand the attention it deserves it deserves online</Typography> */}
            </Stack>

            {/*         <Stack direction={{ xs: "column", md: "row" }} justifyContent={"center"} gap={{ xs: "25px", md: "85px" }} sx={{ mb: 10, display: { xs: "none", md: "flex" } }} flexWrap={"wrap"}>
              {services.map((e) => (
                <Card sx={{ width: { xs: "90%", md: "20%", lg: "12%" }, borderRadius: { xs: "0px", md: "15px" }, bgcolor: "#F5EDDC", cursor: "pointer" }}>
                  <Stack elevation={5} direction={"column"} alignItems={"center"} justifyContent={"space-between"} sx={{ height: "100%" }}>
                    <Typography variant="h6" sx={{ fontFamily: 'Poppins' }}>{e.name}</Typography>
                    <Box component={"img"} src={e.img} sx={{ width: "100%", mt: 3 }}>
                    </Box>
                  </Stack>
                </Card>
              ))}
            </Stack>

            <Stack direction={{ xs: "column", md: "row" }} alignItems={"center"} gap={{ xs: "25px", md: "85px" }} sx={{ mb: 10, display: { xs: "flex", md: "none" } }} flexWrap={"wrap"}>
              {services.map((e) => (
                <Card sx={{ width: { xs: "90%", md: "12%" }, borderRadius: { xs: "0px", md: "15px" }, bgcolor: "#F5EDDC" }} elevation={5}>
                  <Stack direction={"column"} alignItems={"center"} justifyContent={"space-between"} sx={{ height: "100%" }}>
                    <Typography variant="h6" sx={{ fontFamily: 'Poppins' }}>{e.name}</Typography>
                    <Box component={"img"} src={e.img} sx={{ width: "100%", mt: 3 }}>
                    </Box>
                  </Stack>
                </Card>
              ))}
            </Stack> */}

            <CardFlip />



            <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} sx={{ mb: 5 }}>
              <Divider sx={{ width: "29%", mr: 2, bgcolor: "red", border: "2px solid #3F3D56" }} />
              <Typography sx={{ fontFamily: 'Poppins', fontSize: { xs: "30px", md: "50px" } }}>Projects</Typography>
              <Divider sx={{ width: "10%", ml: 2, border: "2px solid #3F3D56" }} />
            </Stack>

            {/* <Stack alignItems={"center"}><Typography sx={{mb:5, fontFamily:"Poppins", fontSize: { xs: "30px", md:"50px"}}}>Photos</Typography></Stack> */}

            <Stack direction={{ xs: "column", md: "row" }} justifyContent={"space-between"} alignItems={"center"} gap={{ xs: "25px", md: "0px" }} mb={{ xs: 5, md: 15 }}>
              <Stack alignItems={"center"} sx={{ width: { xs: "100%", md: "50%" }, mb: 3 }}>
                <Typography sx={{ fontFamily: "Poppins", fontStyle: "bold", ml: 1, mr: 1, mb: { xs: 1, md: 2 }, fontSize: { xs: "35px", md: "50px", lg: "70px", xl: "90px" } }}>CAPTURE YOUR EVERY MOMENT</Typography>
                <Typography sx={{ fontFamily: "Poppins", textAlign: "justify", ml: 1, mr: 1, mr: { md: 20, lg: 30 } }}>Capture all those unique moments with friends and family in an instant.</Typography>
              </Stack>

              <Box sx={{ borderRadius: { md: "50px", xs: "0px" }, width: { xs: "100%", md: "50%" } }}>
                <Carousel showArrows={false} showIndicators={true} showThumbs={false} infiniteLoop={true} autoPlay={true} interval={3000} showStatus={false} stopOnHover={false} autoFocus={true}>
                  <Box component={"img"} src={web1} sx={{ width: { md: "50vw", xs: "100vw" }, height: { xs: "50vh", md: heights } }} />
                  <Box component={"img"} src={web1} sx={{ width: { md: "50vw", xs: "100vw" }, height: { xs: "50vh", md: heights } }} />
                  <Box component={"img"} src={web1} sx={{ width: { md: "50vw", xs: "100vw" }, height: { xs: "50vh", md: heights } }} />
                  <Box component={"img"} src={web1} sx={{ width: { md: "50vw", xs: "100vw" }, height: { xs: "50vh", md: heights } }} />
                  <Box component={"img"} src={web1} sx={{ width: { md: "50vw", xs: "100vw" }, height: { xs: "50vh", md: heights } }} />
                </Carousel>
              </Box>
            </Stack>

            <Box>
              <Stack direction={{ xs: "column", md: "row" }} justifyContent={"space-between"} gap={{ xs: "25px", md: "35px" }} sx={{ mb: 10 }} flexWrap={"wrap"}>
                {youtubevideourl.map((e) => (
                  <Card sx={{ width: { xs: "100%", md: "30%" }, borderRadius: "15px" }} elevation={5}>
                    {e ?
                      <ReactPlayer
                        url={e}
                        volume='1'
                        muted
                        playing={true}
                      /> : <Skeleton />
                    }
                  </Card>
                ))}
              </Stack>
              {/* 
              <Box sx={{ overflow: 'hidden' }}>
                <Media />
              </Box> */}

              <Stack direction={{ xs: "column", sm: "row" }} justifyContent={"space-around"} gap={{ xs: "25px", md: "0px" }} sx={{ mb: 10 }}>
                {counts.map((e) => (
                  <Stack alignItems={"center"}>
                    <CountUp enableScrollSpy={true} end={e.count} duration={5} suffix=" +" style={{ fontFamily: "Poppins", fontSize: "50px" }} />
                    <Typography style={{ fontFamily: "Poppins", fontSize: "25px" }}>{e.name}</Typography>
                  </Stack>
                ))}
              </Stack>

            </Box>
          </Box>
        </Stack>
      </Box>

      <ScrollTop {...props}>
        <Fab size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
      <Footer />

      {/* <Box sx={{position:"fixed", top:"50vh"}}>
      <Stack direction={"column"}>
      {footer.map((text) => (<IconButton sx={{bgcolor:"black", borderRadius:"30px",mb:2}}>{text}</IconButton>))}
      </Stack>
    </Box> */}
    </>
  );
}

export default Wedding;